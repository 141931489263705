.css-onkibi {
    width: 14px;
    height: 14px;
    display: inline-block;
    line-height: 14px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: currentColor;
    vertical-align: middle;
}