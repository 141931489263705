body {
  margin: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  -webkit-overflow-scrolling: auto
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(52, 123, 97, 1);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}