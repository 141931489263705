.main-title {
    font-family: "Playfair Display";
    font-size: 32px;
    font-style: italic;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(52, 123, 98, 1);
    padding-bottom: 50px;
}


.main-title span {
    font-style: normal;
    color: rgba(35, 35, 35, 1);
}

.main-box {
    gap: 40px;
    border-radius: 16px;
    box-shadow: 0px 16px 24px 0px rgba(64, 52, 16, 0.08);
    box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.65) inset;
    box-shadow: 0px 2px 2px 1px rgba(255, 255, 255, 0.25) inset;
}


.wrapper {
    background-image: url("../../assets/bg-pattern-symbol.svg");
    background-color: rgba(246, 245, 242, 1);
}