@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair Display'), url(./assets/PlayfairDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/Poppins-Regular.ttf) format('truetype');
}

.main {
  height: 100vh;
  background-image: url("./assets/bg-pattern-symbol.svg");
}